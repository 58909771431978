import { useState, useEffect, useCallback } from 'react';

type ConnectionDetails = {
  success: boolean;
  status: string;
  connectionId: string;
  connectionKind: string;
  connectionIdentifier: string;
};

type Connections = { [key: string]: ConnectionDetails };

const useIntegrationSetup = (initialIntegrationCode?: string) => {
  const isBrowser = typeof window !== 'undefined';

  const [integrationCode, setIntegrationCode] = useState<string>(() => {
    if (isBrowser) {
      return initialIntegrationCode || localStorage.getItem('integrationSetupCode') || '';
    }
    return initialIntegrationCode || '';
  });
  const [integrationConnections, setIntegrationConnections] = useState<Connections>({});

  useEffect(() => {
    if (isBrowser && integrationCode) {
      localStorage.setItem('integrationSetupCode', integrationCode);
      try {
        const savedConnections = JSON.parse(localStorage.getItem(integrationCode) || '{}') as Connections;
        setIntegrationConnections(savedConnections);
      } catch (error) {
        console.error('Failed to load connections from localStorage:', error);
      }
    }
  }, [integrationCode, isBrowser]);

  const addConnection = useCallback((connectionKind: string, connectionDetails: ConnectionDetails) => {
    if (isBrowser) {
      try {
        const updatedConnections = { ...integrationConnections, [connectionKind]: connectionDetails };
        setIntegrationConnections(updatedConnections);
        localStorage.setItem(integrationCode, JSON.stringify(updatedConnections));
      } catch (error) {
        console.error('Failed to add connection:', error);
      }
    }
  }, [integrationConnections, integrationCode, isBrowser]);

  const clearConnections = useCallback(() => {
    if (isBrowser) {
      try {
        setIntegrationConnections({});
        localStorage.removeItem(integrationCode);
        setIntegrationCode('');
        localStorage.removeItem('integrationSetupCode');
      } catch (error) {
        console.error('Failed to clear connections:', error);
      }
    }
  }, [integrationCode, isBrowser]);

  return { integrationConnections, addConnection, clearConnections, setIntegrationCode, integrationCode };
};

export default useIntegrationSetup;